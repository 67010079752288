<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showEditButton
    showCreateButton
    showDeleteButton
    @create="onCreate('ApiProxyProjectCreate')"
    @edit="(id) => onEdit('ApiProxyProjectEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'ApiProxyProjects',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'ApiProxyProjects',
      tableCaption: 'Проекты, использующие ApiProxy',
      breadcrumbs: [
        {
          text: 'Публикация приложений',
          route: { name: 'PublishingApplicationsMain' },
        },
        {
          text: 'Проекты, использующие ApiProxy',
        },
      ],
      tableHeaders: [
        { text: 'ID', alias: 'id', order: 'id' },
        { text: 'Название проекта', alias: 'name', order: 'name' },
        { text: 'Гуид, с которым проект будет обращаться к прокси', alias: 'guid', order: 'guid' },
        { text: 'Разрешено использование сервиса DaData', alias: 'canUseDaData', order: 'canUseDaData' },
        { text: 'Разрешено использование сервиса Смс от НИЦ', alias: 'canUseNicSms', order: 'canUseNicSms' },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
